<template>
  <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <model-form
      v-if="model"
      :submit-text="pageTitle"
      :model="model"
      :is-edit="false"
      @on-submit="createModel"
    >
    </model-form>
    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import ModelForm from './components/_Form'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'
import flatry from '@admin/utils/flatry'
import CouponService from '@admin/services/CouponService'

export default {
  name: '',
  data () {
    return {
      pageTitle: '新建优惠劵',
      model: {
        type: 1, // 类型
        scoped_id: 1, // 适用范围
        scoped_body: '', // 适用范围内容
        member_discount: 1, // 会员折扣
        is_wm_use: 1, // 外卖是否可用
        cs: {}, // 用于下拉全局分类与素材
        private: 1, // 是否开放
        startTime: '', // 可用时段
        cycle_type: 1, // 可用周期类型
        cycle_type_data: '', // 可用周期内容
        jump_time: '', // 跳出日期
        zk: { // 折扣
          discount: '',
          max_price: ''
        },
        dx: { // 抵现
          yh_price: '',
          reduce_price: ''
        },
        sp: { // 商品
          price: '',
          type: '',
          sum: 1,
          shop_ids: ''
        },
        hd: { // 活动
          price: '',
          people: '',
          type: 1
        }
      }
    }
  },
  components: {
    ModelForm,
    PlaceholderForm
  },

  async created () {
    const { data } = await flatry(CouponService.create())

    if (data) {
      this.model.cs = data.data
    }
  },
  methods: {
    async createModel (model, success, callback) {
      const { data } = await flatry(CouponService.create(model))
      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({
          path: '/coupon/update',
          query: { id: data.data }
        })
        success()
      }

      callback()
    }
  }
}
</script>
<style lang='' scoped>
</style>
